import jQuery = require('jquery');
window['jQuery'] = window['$'] = jQuery;

/* interface Window {
    'jQuery': jQuery,
    '$': jQuery
} */

import { Common } from './common';

function main()
{
    let common = new Common();
}

main();