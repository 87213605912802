const BTN_TO_TOP = ".js-btn-go-to-top";
const FB_BOX = ".js-fb-box";
const INSTAGRAM_BOX = ".js-instagram-box";

declare var FB: any;
/* declare function instawidget_adjust_height(event: Event): void; */

class Common
{
    private btnToTop: JQuery;
    private fbBox: JQuery;
    private instagramBox: JQuery;
    private windowWidth : number; /* safari iOS fix - window resize on scroll */

    constructor()
    {
        this.btnToTop = $(BTN_TO_TOP);
        this.init();

        this.fbBox = $(FB_BOX);
        this.instagramBox = $(INSTAGRAM_BOX);
    
        this.windowWidth = $(window).width();
        if ((this.fbBox.length) || (this.instagramBox))
        {
            $(window).on("resize", this._onWindowResize.bind(this));
        }
    }

    private init() : void
    {
        this.btnToTop.click(this._onBtnToTopClicked);
    }

    private _onBtnToTopClicked(event: JQuery.Event)
    {
        console.log("test");
        event.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, "slow");
    }

    private _onWindowResize(event: JQuery.Event) : void
    {
        let _windowWidth = $(window).width()
        if (this.windowWidth == _windowWidth)
        {
            return;
        }

        this.windowWidth = _windowWidth;

        let self = this;
        setTimeout(
            function()
            {
                self._changeFBPagePlugin();
                self._updateInstagramWidget();
            }, 500
        );
    }

    private _changeFBPagePlugin() : void
    {
        let containerWidth : number;
        containerWidth = parseInt(this.fbBox.width().toFixed(0));

        if (!isNaN(containerWidth))
        {
            this.fbBox.html('<div class="fb-page" data-href="https://www.facebook.com/Nioisupertw/" data-tabs="timeline" data-width="' + containerWidth + '" data-height="669" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/Nioisupertw/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Nioisupertw/">Nioisupertw</a></blockquote></div>');
        }

        if (typeof FB !== 'undefined' ) {
            FB.XFBML.parse();
        }
    }

    private _updateInstagramWidget() : void
    {
        /* let containerWidth : number;
        containerWidth = parseInt(this.instagramBox.width().toFixed(0));

        let _instagramBox = this.instagramBox.get(0) as HTMLElement;

        if (!isNaN(containerWidth))
        {
            _instagramBox.innerHTML = '<a href="https://instawidget.net/v/user/nioisupertw" id="link-429a8af7b129a6add9077903575ed676fc3d54bf77ba5da624a8f7bd9a95e29c">@nioisupertw</a><script src="https://instawidget.net/js/instawidget.js?u=429a8af7b129a6add9077903575ed676fc3d54bf77ba5da624a8f7bd9a95e29c&width=100%"></script>';
        } */

        /* let ev: any = {};
        ev.origin = 'https://instawidget.net';
        ev.data = 
        instawidget_adjust_height(ev); */
    }
}

export { Common };